#text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;

    text-align: justify;
    font-family: monospace;
}
#return {
    margin-top: 18px;
}